.select-interval-form-group {
    margin-bottom: 1rem !important;
}
.select-interval-form-group select {
    border: 2px solid #e4e6ef !important;
}

.decubitus-table {
    background-color: #ffffff !important;
    border: 2px solid #e4e6ef;
}

.current-interval-row {
    background-color: #ddddff !important;
    border-top: 2px solid #6f6fd8 !important;
    border-bottom: 2px solid #6f6fd8 !important;
    border-left: 2px solid #6f6fd8 !important;
}
.current-position-row {
    background-color: #ddddff !important;
    border-top: 2px solid #6f6fd8 !important;
    border-bottom: 2px solid #6f6fd8 !important;
    border-right: 2px solid #6f6fd8 !important;
}

.decubitus-badge {
    color: #223d17;
    font-weight: bold !important;
}
