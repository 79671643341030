.charts_container {
    display: flex;
    justify-content: flex-start;
}

.charts_content_left {
    width: 45vw;
}

.charts_content_right {
    display: grid;
    align-content: space-between;
}

@media (max-width: 990px) {
    .donut_right, .pie_right {
        margin-top: 10px;
    }

    .pie_right {
        width: 100%;
    }

    .pie_left {
        width: 100%;
    }
}

@media (min-width: 991px) and (max-width: 1500px) {
    .pie_right {
        width: 83%;
        margin-top: 10px;
    }

    .pie_left {
        width: 83%;
    }
}

@media (max-width: 1500px) {
    .charts_content_right > div {
        width: 70vw;
    }

    .charts_content_left {
        width: 70vw;
    }

    .charts_content_left > div {
        width: 70vw;
    }   

    .charts_container {
        flex-direction: column;
        display: grid;
        justify-content: center
    }

    .charts_content_right {
        margin-top: 10px;
        width: 70vw;
    }

}



