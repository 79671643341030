@charset "UTF-8";
.loading-icon {
  animation: spin 2s linear infinite; /* Altere a duração e o estilo da animação conforme necessário */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}