.login-bg {
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 1359px) {
  .login-bg-img {
    background-image: url("/media/bg/bg-small.jpg");
  }
}
@media (min-width: 1360px) {
  .login-bg-img {
    background-image: url("/media/bg/bg-large.webp");
  }
}

.navi-button {
  background: none;
  border: none;
  padding: 0.75rem 0.75rem;
  cursor: pointer;
}


.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

/* lsauer.com, 2015 */
.left-flexible-right-fixed .container {
  height: auto;
  overflow: hidden;
}
.left-flexible-right-fixed .left-inner{
   width: 100%;
}
.left-flexible-right-fixed .right {
   width: auto;
   float: right;
}
.left-flexible-right-fixed .left {
   width: auto;
   overflow: hidden;
   float: none !important;
}

.card-scroll-h500 {
  @extend .card-scroll-hCustom;
  max-height: 500px;
}
.card-scroll-h400 {
  @extend .card-scroll-hCustom;
  max-height: 400px;
}
.card-scroll-h300 {
  @extend .card-scroll-hCustom;
  max-height: 300px;
}
.card-scroll-h200 {
  @extend .card-scroll-hCustom;
  max-height: 200px;
}
.card-scroll-h100 {
  @extend .card-scroll-hCustom;
  max-height: 100px;
}
.card-scroll-h50 {
  @extend .card-scroll-hCustom;
  max-height: 50px;
}
.card-scroll-hCustom {
  position: relative;
  overflow: auto;
}

.invisble-modal .modal-content {
  background: none;
  box-shadow: none;
}

.grey-modal .modal-content {
  background: #EEF0F8;
  box-shadow: none;
}

@media print
{
  .noprint { display:none; opacity:0; }
  @page {
    margin: 0; 
    background: transparent;
  }
  body { margin: 1.6cm; }
}

[data-href] { cursor: pointer; }

.no-transform {
  text-transform: none !important;
}

.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  margin-bottom: 15px;
}

.pagination li {
  margin: 0 5px;
  cursor: pointer;
}

.pagination li a {
  color: #000;
  padding: 8px 16px;
}

.pagination li.active a {
  background-color: #007bff;
  color: #fff;
  border-radius: 10%;
  padding: 8px 16px;
}

.pagination-container {
  background-color: white;
  padding: 16px 0 2px 0;
  margin-bottom: 10px;
}

@media (max-width: 400px) {
  .pagination li {
    margin: 0;
  }
  
  .pagination{
    padding: 2px 4px;
    font-size: 8px;
  }

  .pagination li.active a {
    padding: 2px 4px;
  }

  .pagination-container {
    padding: 8px 0 0px 0;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.3;
}

#toast-container {
  margin-top: 1rem;
}

#toast-container.toast-top-full-width>.toast {
  max-width: 570px;
}

#toast-container>.toast-info {
  background-color: $info;
  opacity:1;
  box-shadow:0px 0px 10px $info;
}
#toast-container>.toast-info:hover {
  box-shadow:0px 0px 10px $info;
}

#toast-container>.toast-warning {
  background-color: $warning;
  opacity:1;
  box-shadow:0px 0px 10px $warning;
}
#toast-container>.toast-warning:hover {
  box-shadow:0px 0px 10px $warning;
}

#toast-container>.toast-error {
  background-color: $danger;
  opacity:1;
  box-shadow:0px 0px 10px $danger;
}
#toast-container>.toast-error:hover {
  box-shadow:0px 0px 10px $danger;
}
